const theme = {
  colors: {
    primary: '#a08d40', // Field Drab
    primaryHover: '#1f1c75',
    primaryLight: '#faf9f3', // Baby Powder
    secondary: '#2c27a8',
    secondaryHover: '#e8e5d9', // International Klein Blue
    secondaryLight: '#9881c5', // Laevnder
    white: '#ffffff',
    text: '#333333',
    background: '#f1ead4' // Ecru
  },
  fonts: {
    main: "'Open Sans', sans-serif",
    heading: "'Zilla Slab', sans-serif",
    code: "'Source Code Pro', monospace"
  },
  fontSizes: {
    small: '0.8rem',
    medium: '1rem',
    large: '1.2rem',
  },
  spacing: {
    xs: '4px',
    s: '8px',
    m: '12px',
    l: '16px',
    xl: '20px'
  },
  borderRadius: {
    small: '4px',
    medium: '10px',
    large: '16px',
  },
  borderThickness: {
    small: '1px',
    medium: '2px',
    large: '4px'
  }
};

export default theme;