import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from './Input';
import Button from './Button';
import LogoutButton from './LogoutButton';
import { STRINGS } from '../constants/strings';

function Dashboard() {
  const [user, setUser] = useState<any>(null);
  const [url, setUrl] = useState<string>('');
  const [icpResult, setIcpResult] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('/api/auth/user', {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error('Not authenticated');
        }
        const userData = await response.json();
        setUser(userData);
      } catch (error) {
        navigate('/');
      }
    };

    checkAuth();
  }, [navigate]);

  const handleGenerateIcp = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('/api/app/generate-icp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          companyUrl: url,
          numberOfTitles: 1
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to generate ICP');
      }
      const result = await response.json();
      setIcpResult(result);
    } catch (error) {
      console.error('Error generating ICP:', error);
      setError('Failed to generate ICP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!user) return null;

  return (
    <div>
      <h1>{STRINGS.DASHBOARD.WELCOME}</h1>
      <LogoutButton onLogout={() => setUser(null)} />
      <Input 
        id='icpUrl' 
        placeholder='wfco.co' 
        type="text" 
        value={url} 
        onChange={e => setUrl(e.target.value)}
      >
        {STRINGS.DASHBOARD.INPUT_LABEL}
      </Input>
      <Button type="button" onClick={handleGenerateIcp} disabled={isLoading}>
        {isLoading ? 'Generating...' : 'Generate ICP'}
      </Button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {icpResult && (
        <div>
          <h2>ICP Result:</h2>
          <pre>{JSON.stringify(icpResult, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default Dashboard;