import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button'; // Assuming you have a Button component

interface LogoutButtonProps {
  onLogout: () => void;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    console.log('Logout button clicked');
    try {
      const response = await fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'include',
      });
  
      console.log('Logout response status:', response.status);
      console.log('Logout response ok:', response.ok);
  
      const responseBody = await response.text();
      console.log('Logout response body:', responseBody);
  
      if (response.ok) {
        console.log('Logout successful, redirecting...');
        onLogout();
        navigate('/');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <Button onClick={handleLogout}>
      Logout
    </Button>
  );
};

export default LogoutButton;