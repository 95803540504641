import { useState, useEffect } from 'react';
import Login from './Login';
import Dashboard from './Dashboard';

function Home() {
  const [authState, setAuthState] = useState<'loading' | 'authenticated' | 'unauthenticated'>('loading');

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('/api/auth/user', {
          credentials: 'include' // This ensures cookies are sent with the request
        });
        if (response.ok) {
          setAuthState('authenticated');
        } else {
          setAuthState('unauthenticated');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setAuthState('unauthenticated');
      }
    };

    checkAuth();
  }, []);

  if (authState === 'loading') {
    return <div>Loading...</div>;
  }

  return authState === 'authenticated' ? <Dashboard /> : <Login />;
}

export default Home;