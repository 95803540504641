import React from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const StyledButton = styled.button<ButtonProps>`
  border: none;
  border-radius: ${props => props.theme.borderRadius.medium};
  cursor: pointer;
  font-family: ${props => props.theme.fonts.main};
  transition: all 0.3s ease;

  ${props => props.fullWidth && css`
    width: 100%;
  `}

  ${props => {
    switch (props.size) {
      case 'small':
        return css`
          font-size: ${props.theme.fontSizes.small};
          padding: ${props.theme.spacing.s} ${props.theme.spacing.m};
          margin: ${props.theme.spacing.s} ${props.theme.spacing.m};
        `;
      case 'large':
        return css`
          font-size: ${props.theme.fontSizes.large};
          padding: ${props.theme.spacing.m} ${props.theme.spacing.l};
          margin: ${props.theme.spacing.m} ${props.theme.spacing.l};
        `;
      default: // medium
        return css`
          font-size: ${props.theme.fontSizes.medium};
          padding: ${props.theme.spacing.m} ${props.theme.spacing.l};
          margin: ${props.theme.spacing.m} ${props.theme.spacing.l};
        `;
    }
  }}

  ${props => {
    switch (props.variant) {
      case 'secondary':
        return css`
          background-color: ${props.theme.colors.secondary};
          color: ${props.theme.colors.primary};
          &:hover {
            background-color: ${props.theme.colors.secondaryHover};
          }
        `;
      case 'tertiary':
        return css`
          background-color: transparent;
          color: ${props.theme.colors.primary};
          border: 1px solid ${props.theme.colors.primary};
          &:hover {
            background-color: ${props.theme.colors.primaryLight};
          }
        `;
      default: // primary
        return css`
          background-color: ${props.theme.colors.primary};
          color: ${props.theme.colors.white};
          &:hover {
            background-color: ${props.theme.colors.primaryHover};
          }
        `;
    }
  }}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Button: React.FC<ButtonProps> = ({ 
  children, 
  variant = 'primary', 
  size = 'medium', 
  fullWidth = false,
  onClick,
  disabled = false,
  type = 'button'
}) => {
  return (
    <StyledButton 
      variant={variant} 
      size={size} 
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default Button;