import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${props => props.theme.fonts.main};
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.fonts.heading};
    color: ${props => props.theme.colors.primary};
  }

  input {
    font-family: ${props => props.theme.fonts.code};
    border-radius: ${props => props.theme.borderRadius.medium};
    border: ${props => props.theme.borderThickness.medium} solid ${props => props.theme.colors.primary};
    margin: ${props => props.theme.spacing.m};
    padding: ${props => props.theme.spacing.s};
  }

  label {
    font-family: ${props => props.theme.fonts.code};
  }
`;

export default GlobalStyles;