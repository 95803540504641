import { useState } from 'react';
import Input from './Input';
import Button from './Button';

function Login() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSendLoginLink = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
  
    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error + (data.details ? ` (${data.details})` : ''));
      }
      
      setMessage(data.message || 'Check your email for the login link!');
    } catch (error) {
      if (error instanceof Error) {
        setMessage(`Error: ${error.message}`);
      } else {
        setMessage('An unexpected error occurred. Please try again.');
      }
      console.error('Login error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSendLoginLink} className="login_container">
      <p>Please enter your email address</p>
      <Input id='email' placeholder='jane@example.com' type='email' value={email} onChange={e => setEmail(e.target.value)}>Email Address</Input>
      <Button type="submit" disabled={loading}>
        {loading ? 'Sending...' : 'Send me a login link'}
      </Button>
      {message && <p>{message}</p>}
    </form>
  );
}

export default Login;