import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';


type InputProps = {
  children: string;
  value: string;
  type: string;
  placeholder: string;
  id: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

function Input({ children, value, id, placeholder, type, onChange }: InputProps) {
  return (
    <div>
      <label htmlFor={id}>{children}</label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required
        aria-describedby="email-description"
      />
    </div>
  );
}

export default Input;